/* Wrapper */
.closable-wrapper {
  position: relative;
  display: inline-block; /* Adjust if needed */
  /* visibility: hidden; */
  /* background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: box-shadow 0.3s; */
  background-color: #00000000;
}

/* Content */
.closable-wrapper .content {
  position: relative;
  /* visibility: hidden; */
  background-color: #00000000;
}

/* Close Button */
.close-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #fff;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, color 0.3s;
  opacity: 0;
  z-index: 10;
  transition: all ease-in-out .2s;
}

.close-button-red {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #fff;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* transition: background 0.3s, color 0.3s; */
  opacity: 0;
  z-index: 10;
  transition: all ease-in-out .2s;
}

/* Show the button on hover */
.closable-wrapper:hover .close-button {
  opacity: 1;
}
.closable-wrapper:hover .close-button-top-left {
  opacity: 1;
}
.closable-wrapper:hover .close-button-bottom-left {
  opacity: 1;
}
.closable-wrapper:hover .close-button-bottom-right {
  opacity: 1;
}


.closable-wrapper:hover .close-button-red {
  opacity: 1;
}
.closable-wrapper:hover .close-button-red-top-left {
  opacity: 1;
}
.closable-wrapper:hover .close-button-red-bottom-left {
  opacity: 1;
}
.closable-wrapper:hover .close-button-red-bottom-right {
  opacity: 1;
}

/* Close button hover effect */
.close-button:hover {
  opacity: 1;
  background: #f0f0f0 !important;
  color: #000 !important;
}
.close-button-top-left:hover {
  opacity: 1;
  background: #f0f0f0 !important;
  color: #000 !important;
}
.close-button-bottom-left:hover {
  opacity: 1;
  background: #f0f0f0 !important;
  color: #000 !important;
}
.close-button-bottom-right:hover {
  opacity: 1;
  background: #f0f0f0 !important;
  color: #000 !important;
}


/* Close button hover effect */
.close-button-red:hover {
  background: #de4444 !important;
  color: #f0f0f0 !important;
}
.close-button-red-top-left:hover {
  background: #de4444 !important;
  color: #f0f0f0 !important;
}
.close-button-red-bottom-left:hover {
  background: #de4444 !important;
  color: #f0f0f0 !important;
}
.close-button-red-bottom-right:hover {
  background: #de4444 !important;
  color: #f0f0f0 !important;
}


  /* Close Button Top-Left */
.close-button-top-left {
  position: absolute;
  top: -10px;
  left: -10px;
  background: #fff;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out .2s;
  opacity: 0;
  z-index: 10;
}

/* Close Button Red Top-Left */
.close-button-red-top-left {
  position: absolute;
  top: -10px;
  left: -10px;
  background: #fff;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out .2s;
  opacity: 0;
  z-index: 10;
}

/* Close Button Bottom-Left */
.close-button-bottom-left {
  position: absolute;
  bottom: -10px;
  left: -10px;
  background: #fff;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out .2s;
  opacity: 0;
  z-index: 10;
}

/* Close Button Red Bottom-Left */
.close-button-red-bottom-left {
  position: absolute;
  bottom: -10px;
  left: -10px;
  background: #fff;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out .2s;
  opacity: 0;
  z-index: 10;
}

/* Close Button Bottom-Right */
.close-button-bottom-right {
  position: absolute;
  bottom: -10px;
  right: -10px;
  background: #fff;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out .2s;
  opacity: 0;
  z-index: 10;
}

/* Close Button Red Bottom-Right */
.close-button-red-bottom-right {
  position: absolute;
  bottom: -10px;
  right: -10px;
  background: #fff;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out .2s;
  opacity: 0;
  z-index: 10;
}