/* Base styles */
body {
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f0f2f5;
  }
  
  .dashboard {
    display: flex;
    min-height: 100vh;
  }
  
  .sidebar {
    width: 250px;
    background-color: #ffffff;
    padding: 20px;
    border-right: 1px solid #ddd;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 0 10px 10px 0;
  }
  
  .sidebar-title {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    /* color: #333; */
  }
  
  .accordion {
    margin-top: 20px;
  }
  
  .accordion-header {
    padding: 10px;
    cursor: pointer;
    background-color: #f9f9f9;
    border-radius: 5px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: background-color 0.3s ease;
  }
  
  .accordion-header:hover {
    background-color: #f1f1f1;
  }
  
  .accordion-header h3 {
    margin: 0;
    font-size: 18px;
    /* color: #444; */
  }
  
  .accordion-content {
    padding: 10px 15px;
    background-color: #f0f2f5;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  }
  
  .accordion-content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .accordion-content ul li {
    padding: 5px 0;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .accordion-content ul li:hover {
    color: #007bff;
  }

  .accordion-content2 {
    /* padding: 10px 15px; */
    /* background-color: #f0f2f5; */
    border-radius: 5px;
    /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05); */
  }
  
  .accordion-content2 ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .accordion-content2 ul li {
    padding: 5px 0;
    cursor: pointer;
    transition: all ease-in-out .2s;
    /* transition: color 0.3s ease; */
  }
  
  .accordion-content2 ul li:hover {
    color: #007bff;
  }

  .accordion-content2 li:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 5px;
    border-radius: 5px;
    transition: all ease-in-out .2s;
  }

  .dark .accordion-content2 li:hover {
    color: #cc7c00;
    box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
  }
  
  .content {
    /* flex-grow: 1; */
    /* padding: 20px; */
    /* width: 100%; */
    background-color: #fafafa;
    border-radius: 10px;
    margin: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .btn {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .btn:hover {
    background-color: #0056b3;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  /* Dark mode styles */
  .dark .sidebar {
    background-color: #2e2e2e;
    color: #ffffff;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.2);
  }
  
  .dark .accordion-header {
    background-color: #3a3a3a;
    color: #fff;
  }
  
  .dark .accordion-header:hover {
    background-color: #474747;
  }
  
  .dark .accordion-content .accordion-content2 {
    background-color: #424242;
    color: #d9d9d9;
  }
  
  .dark .content {
    background-color: #1e1e1e;
    color: #e1e1e1;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  }
  
  .dark .btn {
    background-color: #ff9f1a;
  }
  
  .dark .btn:hover {
    background-color: #cc7c00;
  }
  